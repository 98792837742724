import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AlertColor } from "@mui/material";
import queryString from 'query-string';
interface FormValues {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    password: string;
    address: string;
    state: string;
    zipcode: string;
    instagramLink: string;
    fbLink: string;
    agreeToTerms: boolean;
}

interface TouchedFields {
    firstName: boolean;
    lastName: boolean;
    phoneNumber: boolean;
    email: boolean;
    password: boolean;
    address?: boolean;
    state?: boolean;
    zipcode?: boolean;
    instagramLink?: boolean;
    fbLink?: boolean;
    agreeToTerms?: boolean;
}

import * as Yup from "yup";
let otpt = "";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    location: any;
    history: { push: any };
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    formValues: FormValues;
    countryCodeSelected: string,
    showPassword: boolean,
    formErrors: Partial<FormValues>;
    touchedFields: TouchedFields;
    data: Object,
    step: number;
    errorMessages: string[];
    showToast: boolean,
    toastMessage: string,
    toastSeverity: AlertColor;
    userRole: string;
    passwordValidations: {
        uppercase: boolean,
        lowercase: boolean,
        number: boolean,
        length: boolean
    },
    otp: [string, string, string, string],
    otpToken: string,
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EmailAccountUserRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailSignupCallId: any = "";
    apiVerifyOtpCallId: any = "";
    resendEmailSignupCallId: any = "";
    validationApiCallId: string = "";
    labelTitle: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            formValues: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                password: "",
                address: "",
                state: "",
                zipcode: "",
                instagramLink: "",
                fbLink: "",
                agreeToTerms: false,
            },
            countryCodeSelected: "+91",
            showPassword: false,
            formErrors: {},
            touchedFields: {
                firstName: false,
                lastName: false,
                phoneNumber: false,
                email: false,
                password: false,
                address: false,
                state: false,
                zipcode: false,
                instagramLink: false,
                fbLink: false,
                agreeToTerms: false,
            },
            passwordValidations: {
                uppercase: false,
                lowercase: false,
                number: false,
                length: false
            },
            data: {},
            step: this.getStepFromPath(),
            errorMessages: [],
            showToast: false,
            toastMessage: '',
            toastSeverity: 'success' as AlertColor,
            userRole: "",
            otp: ["-", "-", "-", "-"],
            otpToken: ""
        };

        this.labelTitle = configJSON.labelTitle;
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        const storedFormData = localStorage.getItem("signUpFormData");
        if (storedFormData) {
            const parsedData = JSON.parse(storedFormData);

            this.setState({ formValues: parsedData }, () => {
                let password = this.state.formValues.password;
                this.validatePassword(password);
            });
        }
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));

        const values = queryString.parse(this.props.location.search);
        this.setState({
            userRole: values.role === "chef" ? "chef" : "customer",
        });
        // Customizable Area End
    }
    // Customizable Area End
    // Customizable Area Start

    getStepFromPath() {
        const { search } = this.props.location;
        const params = new URLSearchParams(search);
        const step = params.get('step');
        return step ? parseInt(step, 10) : 1;
    }

    componentDidUpdate(prevProps: any) {
        const { search } = this.props.location;
        const prevSearch = prevProps.location.search;

        if (search !== prevSearch) {
            this.setState({ step: this.getStepFromPath() });
        }
    }
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    };
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (!apiRequestCallId) return;

        if (apiRequestCallId === this.apiEmailSignupCallId) {
            this.handleEmailSignupResponse(responseJson);
        }
        if (apiRequestCallId === this.apiVerifyOtpCallId) {
            this.handleOtpVerifyResponse(responseJson);
        }
        if (apiRequestCallId === this.resendEmailSignupCallId) {
            this.handleResendEmailResponse(responseJson);
        }
        // Customizable Area End
    }
    // Customizable Area Start

    handleEmailSignupResponse(responseJson: any) {
        if (responseJson?.meta?.token) {
            this.onSignupSuccess(responseJson);
        } else {
            this.onSignupFail(responseJson);
        }
    }

    onSignupSuccess(responseJson: any) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveSignupUserData(responseJson);
        this.handleShowToast("Registration successful!", "success");
        this.props.history.push("/activateAccount");
    }

    onSignupFail(responseJson: any) {
        const errorMessage = responseJson.errors?.[0]?.account || "Something went wrong";
        this.handleShowToast(errorMessage, "error");
        this.parseApiErrorResponse(responseJson);
        this.sendSignupFailMessage();
    }

    sendSignupFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    saveSignupUserData(responseJson: any) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );
            msg.addData(
                getName(MessageEnum.SessionResponseToken),
                responseJson.meta.token
            );

            this.send(msg);

            this.setState({
                otpToken: responseJson.meta.otp_token
            });

            otpt = responseJson.meta.otp_token;




            this.setState({
                formValues: {
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    password: "",
                    address: "",
                    state: "",
                    zipcode: "",
                    instagramLink: "",
                    fbLink: "",
                    agreeToTerms: false
                },
                touchedFields: {
                    firstName: false,
                    lastName: false,
                    phoneNumber: false,
                    email: false,
                    password: false,
                    address: false,
                    state: false,
                    zipcode: false,
                    agreeToTerms: false
                },
                formErrors: {},
                step: 1,
            });
        }
    }


    doEmailSignup = (values: FormValues): boolean => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const attrs: { [key: string]: any } = {
            first_name: values.firstName,
            password: values.password,
            email: values.email,
            last_name: values.lastName,
            address: values.address,
            full_phone_number: `${this.state.countryCodeSelected}${values.phoneNumber}`,
            zipcode: values.zipcode,
            state: values.state
        };

        if (this.state.userRole === "chef") {
            if (values.instagramLink) {
                attrs.insta_link = values.instagramLink;
            }
            if (values.fbLink) {
                attrs.facebook_link = values.fbLink;
            }
        }

        const data = {
            type: "email_account",
            attributes: {
                ...attrs,
                user_type: this.state.userRole,
            },
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEmailSignupCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SignupAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }


    resendEmail = () => {
        const token = localStorage.getItem("authToken");
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.resendEmailSignupCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.ResendAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleResendEmailResponse(responseJson: any) {
        if (responseJson?.meta?.token) {
            this.onVerifyResendEmailSuccess(responseJson);
        } else {
            this.onVerifyResendEmailFail(responseJson);
        }
    }

    onVerifyResendEmailSuccess(responseJson: any) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveResendEmail(responseJson);
        this.handleShowToast("Email Sent Successfully", "success");
    }

    onVerifyResendEmailFail(responseJson: any) {
        let errorMessage = "Something went wrong";

        if (responseJson.errors && responseJson.errors[0] && responseJson.errors[0].otp) {
            errorMessage = responseJson.errors[0].otp;
        }
        this.handleShowToast(errorMessage, "error");

        this.parseApiErrorResponse(responseJson);
        this.sendSignupFailMessage();
    }

    saveResendEmail(responseJson: any) {
        if (responseJson) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );

            this.send(msg);
        }
    }



    verifyOTP = () => {
        const otpString = this.state.otp.join("");

        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const data = {
            otp_code: otpString,
            token: otpt
        };

        const httpBody = {
            data: data,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiVerifyOtpCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.OtpVerifyAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleOtpVerifyResponse(responseJson: any) {
        if (responseJson?.messages) {
            this.onVerifyOtpSuccess(responseJson);
        } else {
            this.onVerifyOtpFail(responseJson);
        }
    }

    onVerifyOtpSuccess(responseJson: any) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveVerifyOtp(responseJson);
        this.handleShowToast("OTP Verified", "success");
        localStorage.removeItem("signUpFormData");
        this.props.history.push("/successAccount");
    }

    onVerifyOtpFail(responseJson: any) {
        let errorMsg = "Something went wrong";
        if (responseJson.errors && responseJson.errors[0] && responseJson.errors[0].otp) {
            errorMsg = responseJson.errors[0].otp;
        }
        this.handleShowToast(errorMsg, "error");
        this.parseApiErrorResponse(responseJson);
        this.sendSignupFailMessage();
    }

    saveVerifyOtp(responseJson: any) {
        if (responseJson) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );

            this.send(msg);

            this.setState({
                otp: ["-", "-", "-", "-"]
            });
        }
    }


    handleCountryCodeChange = (event: any) => {
        this.setState({
            countryCodeSelected: event.target.value,
        });
    };


    handleShowToast = (message: string, severity: AlertColor) => {
        this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
    }


    handleCloseToast = () => {
        this.setState({ showToast: false });
    }

    handleChange = (field: keyof FormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (field === "password") {
            this.validatePassword(value);
        }
        this.setState(
            prevState => ({
                formValues: {
                    ...prevState.formValues,
                    [field]: value,
                },
                touchedFields: {
                    ...prevState.touchedFields,
                    [field]: true,
                },
            }),
            () => {
                this.validateForm(this.state.step);
                localStorage.setItem("signUpFormData", JSON.stringify(this.state.formValues));
            }
        );
    };

    handleNext = async () => {
        const allTouchedFields = {
            firstName: true,
            lastName: true,
            phoneNumber: true,
            email: true,
            password: true,
        };

        this.setState({ touchedFields: allTouchedFields }, async () => {
            const isValid = await this.validateForm(this.state.step);

            if (isValid) {
                const nextStep = this.state.step + 1;
                this.setState({ step: nextStep }, () => {
                    this.props.history.push(`/userRegistration?role=customer&step=${nextStep}`);
                });
            }
        });
    };

    handleBack = () => {
        const nextStep = this.state.step - 1;
        this.setState(() => {
            this.props.history.push(`/userRegistration?role=customer&step=${nextStep}`);
        })
    } 

    step1Schema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        phoneNumber: Yup.string().required("Phone Number is required"),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().required("Password is required").min(5, "Password must be minimum 5 characters"),
    });

    step2Schema = Yup.object().shape({
        address: Yup.string().required("Address is required"),
        state: Yup.string().required("State is required"),
        zipcode: Yup.string().required("Zipcode is required"),
        instagramLink: Yup.string().url("Invalid Instagram URL"),
        fbLink: Yup.string().url("Invalid Facebook URL"),
        agreeToTerms: Yup.boolean().oneOf([true], "You have to agree with Privacy Policy and Terms and Conditions to Sign up."),
    });

    validateForm = async (step: number) => {
        let schema = step === 1 ? this.step1Schema : this.step2Schema;
        try {
            await schema.validate(this.state.formValues, { abortEarly: false });
            this.setState({ formErrors: {} });
            return true;
        } catch (err) {
            const error = err as Yup.ValidationError;
            const errors = error.inner.reduce((acc: any, err: Yup.ValidationError) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            this.setState({ formErrors: errors });
            return false;
        }
    };


    validatePassword = (password: any) => {
        const uppercase = /[A-Z]/.test(password);
        const lowercase = /[a-z]/.test(password);
        const number = /\d/.test(password);
        const length = password.length >= 8;

        this.setState(
            prevState => ({
                formValues: {
                    ...prevState.formValues,
                    password,
                },
                passwordValidations: {
                    uppercase,
                    lowercase,
                    number,
                    length
                }
            }),
            () => {
                localStorage.setItem("signUpFormData", JSON.stringify(this.state.formValues));
            }
        );
    }


    handleSubmit = async () => {
        if (this.state.step === 2) {
            this.setState({
                touchedFields: {
                    ...this.state.touchedFields,
                    address: true,
                    state: true,
                    zipcode: true,
                    instagramLink: true,
                    fbLink: true,
                    agreeToTerms: true,
                }
            }, async () => {
                const isValid = await this.validateForm(this.state.step);
                if (isValid) {
                    this.doEmailSignup(this.state.formValues);
                }
            });
        }
    };




    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                agreeToTerms: checked,
            },
            touchedFields: {
                ...prevState.touchedFields,
                agreeToTerms: true,
            },
        }), () => {
            this.validateForm(this.state.step);
        });
    };

    handleOTPChange = (index: any, value: any) => {
        const newOtp = [...this.state.otp] as [string, string, string, string];
        if (/^\d$/.test(value)) {
            newOtp[index] = value;
        } else if (value === "") {
            newOtp[index] = "-";
        }
        this.setState({ otp: newOtp });

        if (value !== "" && index < this.state.otp.length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    handleKeyDown = (index: any, event: any) => {
        if (event.key === "Backspace" && this.state.otp[index] === "-") {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) {
                prevInput.focus();
            }
        }
    };

    goToLogin = () => {
        this.props.history.push("/login")
    }

    goToVerifyCode = () => {
        this.props.history.push("/otp")
    }

    // Customizable Area End
}
